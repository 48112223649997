import { client } from '../api/api.client';
import { WorkforcePlusCompanyFilterDto } from '../api/data/dto/workforce-plus-company-filter.dto';
import { WorkforcePlusCompanyFilter } from '../models/workforce-plus-company-filter.model';

type WorkforcePlusCompanyFilters = {
  getWorkforcePlusCompanyFilter: () => Promise<WorkforcePlusCompanyFilter[]>;
  updateWorkforcePlusFilter: (
    newFilter: WorkforcePlusCompanyFilter[]
  ) => Promise<WorkforcePlusCompanyFilter[]>;
};

const repository = (): WorkforcePlusCompanyFilters => {
  return {
    getWorkforcePlusCompanyFilter: async (): Promise<WorkforcePlusCompanyFilter[]> => {
      const response: WorkforcePlusCompanyFilterDto[] =
        await client.getWorkforceCompanyFilter();

      return response.map((filterKey) =>
        WorkforcePlusCompanyFilter.fromJson(filterKey)
      );
    },

    updateWorkforcePlusFilter: async (
      newFilter: WorkforcePlusCompanyFilter[]
    ): Promise<WorkforcePlusCompanyFilter[]> => {
      const clientConfig: WorkforcePlusCompanyFilterDto[] =
        await client.updateWorkforceCompanyFilter(
          newFilter.map((filter) =>
            WorkforcePlusCompanyFilter.toJson(filter)
          ) as WorkforcePlusCompanyFilterDto[]
        );

      return clientConfig.map((filter) =>
        WorkforcePlusCompanyFilter.fromJson(filter)
      );
    },
  };
};

export const workforcePlusCompanyFilterRepository = repository();
