import { Tos } from '../models/tos.model';
import { client } from '../api/api.client';
import { TosDto } from '../api/data/dto/tos.dto';

type TosRepository = {
  getTosOfCurrentCompany: () => Promise<Tos>;
  updateTosOfCurrentCompany: (tos: Tos) => Promise<Tos>;
};

const repository = (): TosRepository => {
  return {
    getTosOfCurrentCompany: async (): Promise<Tos> => {
      const tos: TosDto = await client.getTosOfCurrentCompany();
      return Tos.fromJson(tos);
    },
    updateTosOfCurrentCompany: async (tos: Tos): Promise<Tos> => {
      const updatedTos: TosDto = await client.updateTosOfCurrentCompany(
        Tos.toJson(tos) as TosDto
      );
      return Tos.fromJson(updatedTos);
    },
  };
};

export const tosRepository = repository();
