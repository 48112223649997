import React, { useEffect, useMemo } from 'react';
import { useForm } from '../../../core/hooks/useForm';
import { Divider, FormGroup, Stack, Typography } from '@mui/material';
import { TextFormField } from '../../../core/components/form/TextFormField.component';
import { StyledFormGroup } from '../Settings.component';
import { WorkforcePlusConfig } from '../../../core/models/workforce-plus-config.model';
import { useWorkforcePlusConfigStore } from '../../../core/store/workforce-plus.store';
import { useCompanyStore } from '../../../core/store/company-store/useCompanyStore';
import { WorkforcePlusFilterSetting } from './WorkforcePlusFilterSetting.component';
import { UUID } from '../../../core/models/typedefs';
import { useSnackbar } from 'notistack';
import { useWorkforcePlusCompanyFilterStore } from '../../../core/store/workforce-plus-company-filter.store';
import { WorkforcePlusCompanyFilter } from '../../../core/models/workforce-plus-company-filter.model';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../core/components/button';

export const WorkforcePlusSetting: React.FC = () => {
  //region STATE
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useCompanyStore();
  const { config, getWorkforcePlusConfig, changeWorkforcePlusConfig } =
    useWorkforcePlusConfigStore();
  const { updateWorkforcePlusFilter } = useWorkforcePlusCompanyFilterStore();

  const form = useForm<ConfigData>({
    defaultValues: useMemo(() => {
      return getDefaultWorkForcePlusValues({
        ...config,
        selectedKeyId: undefined,
        selectedFilter: '',
      });
    }, []),
  });
  const { control, getValues, reset: resetForm, watch } = form;
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      getWorkforcePlusConfig();
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    resetForm((state: ConfigData) =>
      getDefaultWorkForcePlusValues({
        ...config,
        selectedKeyId: state.selectedKeyId,
        selectedFilter: state.selectedFilter,
      }),
    );
  }, [config]);
  //endregion

  //region HANDLER
  const handleSaveWPFData = () => {
    const values = getValues() as ConfigData;
    const changeConfigPromise = changeWorkforcePlusConfig({
      ...config,
      ...getValues(),
    } as WorkforcePlusConfig);
    let updateFilterPromise = Promise.resolve();
    const selectedFilters = values.selectedKeyId === '' ? [] : values.selectedFilter?.split(',') ?? [];
    const workforceFilter: WorkforcePlusCompanyFilter[] = selectedFilters.filter(filter => filter.length > 0).map(
      (filter, index) => {
        return {
          key: values.selectedKeyId ?? '',
          value: filter.trim(),
          index,
          companyId: selectedCompany?.id ?? '',
        };
      },
    );
    updateFilterPromise = updateWorkforcePlusFilter(workforceFilter);

    Promise.all([changeConfigPromise, updateFilterPromise])
      .then(() => {
        enqueueSnackbar(t('settings.successfully_saved_workforce_plus_config'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('settings.failed_to_save_workforce_plus_config'), {
          variant: 'error',
        });
      });
  };
  //endregion

  //region RENDER
  //endregion

  return (
    <Stack spacing={1}>
      <Typography fontSize={18} fontWeight={'bold'}>
        WorkforcePlus
      </Typography>
      <StyledFormGroup>
        <form style={{ width: '100%' }} noValidate>
          <FormGroup>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <TextFormField
                  autoComplete={'off'}
                  control={control}
                  controlName={'url'}
                  label={'Connected WFP-System (URL)'}
                />
                <TextFormField
                  autoComplete={'off'}
                  control={control}
                  controlName={'clientId'}
                  label={'Client-ID'}
                />
                <TextFormField
                  autoComplete={'off'}
                  control={control}
                  controlName={'clientSecret'}
                  label={'Client-Secret'}
                  type={'password'}
                />
              </Stack>
              <Divider />
              <WorkforcePlusFilterSetting
                control={control}
                selectedKeyId={watch('selectedKeyId') as string}
                onFilterKeysChange={(selectedKeyId, selectedFilter) => {
                  resetForm((state: ConfigData) => ({
                    ...state,
                    selectedKeyId: selectedKeyId ?? '',
                    selectedFilter: selectedFilter ?? '',
                  }));
                }}
              />
            </Stack>
            <Button
              sx={{ ml: 'auto' }}
              variant={'contained'}
              onClick={handleSaveWPFData}
            >
              {t('settings.save')}
            </Button>
          </FormGroup>
        </form>
      </StyledFormGroup>
    </Stack>
  );
};

export type ConfigData = WorkforcePlusConfig & {
  selectedKeyId?: UUID;
  selectedFilter: string;
};

const getDefaultWorkForcePlusValues = (configData: ConfigData): ConfigData => {
  return {
    url: configData?.url ?? '',
    clientId: configData?.clientId ?? '',
    clientSecret: configData?.clientSecret ?? '',
    selectedKeyId: configData?.selectedKeyId,
    selectedFilter: configData?.selectedFilter ?? '',
  };
};
