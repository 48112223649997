export class WorkforcePlusCompanyFilterKey {
  id: string;

  name?: string;

  key?: string;

  createdAt?: string;

  updatedAt?: string;

  isAdditionalData: boolean;

  constructor({
    id,
    name,
    key,
    createdAt,
    updatedAt,
    isAdditionalData,
  }: {
    id: string;
    name: string;
    key: string;
    createdAt: string;
    updatedAt: string;
    isAdditionalData: boolean;
  }) {
    this.id = id;
    this.name = name;
    this.key = key;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isAdditionalData = isAdditionalData;
  }

  static fromJson(json: { [x: string]: any }) {
    return new WorkforcePlusCompanyFilterKey({
      id: json.id as string,
      name: json.name as string,
      key: json.key as string,
      createdAt: json.created_at as string,
      updatedAt: json.updated_at as string,
      isAdditionalData: json.is_additional_data as boolean,
    });
  }

  static toJson(config: WorkforcePlusCompanyFilterKey): { [x: string]: unknown } {
    return {
      id: config.id,
      name: config.name,
      key: config.key,
      created_at: config.createdAt,
      updated_at: config.updatedAt,
      is_additional_data: config.isAdditionalData,
    };
  }
}
