/* eslint-disable @typescript-eslint/no-unsafe-assignment
 */
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { MuiColorInput } from 'mui-color-input';
import {
  StyledBox,
  StyledError,
  TextFormFieldProps,
} from './TextFormField.component';

export const ColorFormField: FC<TextFormFieldProps> = ({
  control,
  controlName,
  rules,
  ...rest
}) => {
  const { sx, ...props } = rest;

  return (
    <Controller
      control={control}
      rules={rules}
      name={controlName}
      render={({
        field,
        fieldState: { isTouched, error },
        formState: { isSubmitted },
      }) => {
        return (
          <StyledBox sx={sx}>
            <MuiColorInput
              {...props}
              {...field}
              isAlphaHidden
              format={'hex'}
              variant="outlined"
            />
            {(isTouched || isSubmitted) && error && (
              <StyledError role="alert">
                {error.message?.toString() || 'Invalid input'}
              </StyledError>
            )}
          </StyledBox>
        );
      }}
    ></Controller>
  );
};
