import { instanceToPlain } from 'class-transformer';
import {
  ContentItemFormValues,
  getVisibilityFromFormValues,
} from '../../../models/content-item-form-values';

export class ContentItemDto {
  id?: string;

  icon?: string;

  link: string;

  companyId: string;

  validFrom?: string;

  validUntil?: string;

  userCanHideItem: boolean;

  defaultShowInApp: boolean;

  openInEmbeddedBrowser?: boolean;

  description?: string;

  translations: {
    locale: number;
    title: string;
  }[];

  constructor(
    link: string,
    companyId: string,
    userCanHideItem: boolean,
    defaultShowInApp: boolean,
    translations: {
      locale: number;
      title: string;
    }[],
    validFrom?: string,
    validUntil?: string,
    icon?: string,
    openInEmbeddedBrowser?: boolean,
    description?: string,
    id?: string
  ) {
    this.icon = icon;
    this.companyId = companyId;
    this.link = link;
    this.validFrom = validFrom;
    this.validUntil = validUntil;
    this.userCanHideItem = userCanHideItem;
    this.defaultShowInApp = defaultShowInApp;
    this.openInEmbeddedBrowser = openInEmbeddedBrowser;
    this.description = description;
    this.translations = translations;
    this.id = id;
  }

  static fromForm(formData: ContentItemFormValues, companyId: string) {
    const visibility = getVisibilityFromFormValues(formData);
    return new ContentItemDto(
      formData.link,
      companyId,
      visibility?.userCanHideItem ?? false,
      visibility?.defaultShowInApp ?? true,
      [
        {
          locale: formData.defaultLocale ?? 1,
          title: formData.label,
        },
      ],
      formData.validFrom?.toISO() ?? undefined,
      formData.validUntil?.toISO() ?? undefined,
      formData.imagePath as string,
      formData.openInEmbeddedBrowser,
      formData.description,
      formData.id
    );
  }

  toJson() {
    return instanceToPlain(this);
  }
}
