import { WorkforcePlusConfigDto as JsonWorkforcePlusConfig } from '../api/data/dto/workforce-plus-config.dto';
import { WorkforcePlusConfig } from '../models/workforce-plus-config.model';
import { client } from '../api/api.client';
import { generateUUID } from '../utils/uuid-generator';

type WorkForcePlusConfigRepository = {
  getWorkforcePlusConfig: () => Promise<WorkforcePlusConfig | null>;
  changeWorkforcePlusConfig: (
    newConfig: WorkforcePlusConfig
  ) => Promise<WorkforcePlusConfig>;
};

const repository = (): WorkForcePlusConfigRepository => {
  return {
    getWorkforcePlusConfig: async (): Promise<WorkforcePlusConfig | null> => {
      const response: JsonWorkforcePlusConfig | null =
        await client.getWorkforcePlusConfig();

      if (response === null) {
        return null;
      }

      return WorkforcePlusConfig.fromJson(response);
    },

    changeWorkforcePlusConfig: async (
      newConfig: WorkforcePlusConfig
    ): Promise<WorkforcePlusConfig> => {
      const clientConfig: JsonWorkforcePlusConfig =
        await client.changeWorkforcePlusConfig(
          WorkforcePlusConfig.toJson(newConfig) as JsonWorkforcePlusConfig
        );

      return WorkforcePlusConfig.fromJson(clientConfig);
    },
  };
};

export const workForcePlusConfigRepository = repository();
