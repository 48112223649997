/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthError, createClient, PostgrestError } from '@supabase/supabase-js';
import { FileObject } from '@supabase/storage-js';
import { supabaseAnonKey, supabaseUrl } from '../../environment';
import { UUID } from '../models/typedefs';
import { AuthApiError } from './api-errors';
import { ApiClient } from './api.client';
import { ContentItemUpdateDto } from './data/dto/content-item-update.dto';
import { ContentItemDto } from './data/dto/content-item.dto';
import { UpdateSortingDto } from './data/dto/update-sorting.dto';
import { JSONArray, JSONObject } from './types';

import { companyStore } from '../store/company-store/company-store';
import { FeatureFlagDto } from './data/dto/feature-flag.dto';
import { WorkforcePlusConfigDto } from './data/dto/workforce-plus-config.dto';
import { CompanyPropertyDto } from './data/dto/company-property.dto';
import { WorkforcePlusCompanyFilterDto } from './data/dto/workforce-plus-company-filter.dto';
import { WorkforcePlusFilterKeyDto } from './data/dto/workforce-plus-filter-key.dto';
import { generateUUID } from '../utils/uuid-generator';
import { TosDto } from './data/dto/tos.dto';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _client = createClient(
  !supabaseUrl ? 'http://localhost:8080' : supabaseUrl,
  !supabaseAnonKey ? 'nokey' : supabaseAnonKey
);

export const supabaseClient: ApiClient = {
  //////////////////
  // AUTHENTICATION
  //////////////////

  async logIn(
    email: string,
    password: string,
    companyKey: string
  ): Promise<void> {
    email = `${companyKey}+${email}`;
    const { data, error } = await _client.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw new AuthApiError(error.message, error.status);
    }

    const { data: isAdmin, error: adminError } = await _client.rpc('is_admin', {
      user_id: data?.user?.id,
    });

    if (adminError) throwError(adminError);
    if (isAdmin === false) {
      // we logged in before, so a valid session was created;
      // therefore we also need to clear the session if the user is no admin
      await this.logOut();
      throw new AuthApiError('User not authorized', 401);
    }
  },

  async logOut(): Promise<void> {
    try {
      await _client.auth.signOut();
    } catch (e) {
      console.error(e);
    }
  },

  async isAuthenticated(): Promise<boolean> {
    const { data, error } = await _client.auth.getSession();

    if (error) throwError(error);

    return data.session !== null;
  },

  //////////////////
  // ADMIN
  ///////////////////

  async getAdmins(): Promise<JSONArray> {
    const { data, error } = await _client
      .from('admin_profile')
      .select()
      .eq('company_id', getCompanyId());

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getAdmins');
  },

  async getAdmin(adminId: UUID): Promise<JSONObject> {
    const { data, error } = await _client
      .from('admin_profile')
      .select()
      .eq('id', adminId)
      .limit(1)
      .single();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'getAdmin');
  },

  async addAdmin(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = null;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();

    const { data, error } = await _client.rpc(
      'add_or_update_admin_user',
      request
    );

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'addAdmin');
  },

  async updateAdmin(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = jsonMap.id;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();

    const { data, error } = await _client.rpc(
      'add_or_update_admin_user',
      request
    );

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'updateAdmin');
  },

  async removeAdmin(adminId: UUID, currentPassword?: string): Promise<void> {
    // We can validate the password on the client and do not necessarily need to delete
    // an admin via a special rpc call. Even if an attacker would not use the UI and call
    // the api directly, he would only be able to delete admins but one due to our backend policy
    const { data: isPasswordValid, error: passwordError } = await _client.rpc(
      'validate_password',
      {
        password: currentPassword,
      }
    );

    if (!isPasswordValid) throw new Error('Invalid password');

    if (passwordError) throwError(passwordError);

    const { error } = await _client
      .from('admin_profile')
      .delete()
      .eq('id', adminId);

    if (error) throwError(error);
  },

  //////////////////
  // USER
  ///////////////////

  async getSelfUser(): Promise<JSONObject> {
    const { data: session, error: fetchSessionError } =
      await _client.auth.getUser();

    if (fetchSessionError) throwError(fetchSessionError);

    let profile = {};
    try {
      if (session?.user?.id) {
        profile = await this.getAdmin(session.user.id);
      }
    } catch (e) {
      throw new Error('Could not fetch admin profile');
    }

    const { data: isSuperAdmin, error: adminError } = await _client.rpc(
      'is_super_admin',
      { user_id: session.user?.id }
    );

    if (adminError) throwError(adminError);

    const data = { ...session.user, ...profile, is_super_admin: isSuperAdmin };

    return dataOrThrowInvalidInput<JSONObject>(data, 'getSelfUser');
  },

  async getUser(userId: UUID): Promise<JSONObject> {
    const { data, error } = await _client
      .from('profile')
      .select()
      .eq('id', userId)
      .limit(1)
      .single();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'getUser');
  },

  async getUsers(): Promise<JSONArray> {
    const { data, error } = await _client
      .from('profile')
      .select()
      .eq('company_id', getCompanyId());

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getUsers');
  },

  async getUsersForRange(
    start: number,
    end: number
  ): Promise<{ users: JSONArray; totalCount: number }> {
    const companyId = getCompanyId();
    const { data, count, error } = await _client
      .from('profile')
      .select('*', { count: 'exact' })
      .eq('company_id', companyId)
      .order('last_name', { ascending: true })
      .range(start, end);

    if (error) throwError(error);

    if (data === null) throw new Error(`[getUsersForRange] Invalid input`);

    return {
      users: data,
      totalCount: count ?? 0,
    };
  },

  async addUser(jsonMap: JSONObject): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = null;
    delete request.id;
    request.auth_user_password = null;
    request.user_company_id = getCompanyId();
    request.new_user_name = request.user_name;
    delete request.user_name;

    const { data, error } = await _client.rpc('add_or_update_user', request);

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'addUser');
  },

  async updateUser(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject> {
    const request = { ...jsonMap };

    request.user_id = jsonMap.id;
    delete request.id;
    request.auth_user_password = currentPassword ?? null;
    request.user_company_id = getCompanyId();
    request.new_user_name = request.user_name;
    delete request.user_name;

    const { data, error } = await _client.rpc('add_or_update_user', request);

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONObject>(data, 'updateUser');
  },

  //////////////////
  // COMPANY
  ///////////////////

  async getCompanies(): Promise<JSONArray> {
    const { data, error } = await _client.from('company').select();

    if (error) throwError(error);

    return dataOrThrowInvalidInput<JSONArray>(data, 'getCompanies');
  },

  //////////////////
  // PROFILE PICTURE
  //////////////////

  async getProfilePicture(pictureId: UUID): Promise<Blob | null> {
    // Check if a Profile Picture exists
    const { data, error } = await _client.storage
      .from('profile-pictures')
      .download(`${pictureId}.png`);

    if (error) return null;

    return data;
  },

  //////////////////
  // CROSS CONTENT ITEMS
  //////////////////

  async getCrossContentItems(): Promise<JSONArray> {
    const companyId = getCompanyId();
    const { data } = await _client.functions.invoke<JSONArray>(
      'cross-content-hub/admin?company_id=' + companyId,
      { method: 'GET' }
    );

    return data ?? [];
  },

  async createCrossContentItem(
    crossContentItem: ContentItemDto
  ): Promise<{ [p: string]: any }> {
    const { data, error } = await _client.functions.invoke(
      'cross-content-hub',
      {
        method: 'POST',
        body: crossContentItem.toJson(),
      }
    );

    return data;
  },

  async updateCrossContentItem(
    updateResource: ContentItemUpdateDto
  ): Promise<{ [p: string]: any }> {
    const { data, error } = await _client.functions.invoke(
      'cross-content-hub',
      {
        method: 'PUT',
        body: updateResource,
      }
    );

    return data;
  },

  async deleteCrossContentItem(id: UUID): Promise<void> {
    const { data, error } = await _client.functions.invoke(
      `cross-content-hub/${id}`,
      {
        method: 'DELETE',
      }
    );

    return data;
  },

  async updateSortingOfContentItems(
    ordering: UpdateSortingDto[]
  ): Promise<JSONArray> {
    const { data, error } = await _client.functions.invoke<JSONArray>(
      'cross-content-hub/change-order',
      {
        method: 'PUT',
        body: ordering,
      }
    );

    return data ?? [];
  },

  //////////////////
  // FEATURE FLAGS
  //////////////////

  async getFeatureFlags(): Promise<FeatureFlagDto[]> {
    const { data } = await _client
      .from('feature_flag')
      .select()
      .eq('company_id', getCompanyId());

    return dataOrThrowInvalidInput(data, 'getFeatureFlags');
  },

  async toggleFeatureFlag(
    featureFlag: FeatureFlagDto
  ): Promise<FeatureFlagDto> {
    if (featureFlag.id) {
      const { data, error } = await _client
        .from('feature_flag')
        .update(featureFlag)
        .eq('id', featureFlag.id)
        .select()
        .single();

      return data as FeatureFlagDto;
    } else {
      const companyId = getCompanyId();
      const { data, error } = await _client
        .from('feature_flag')
        .insert({ ...featureFlag, company_id: companyId })
        .select()
        .single();

      return data as FeatureFlagDto;
    }
  },

  //////////////////
  // FILE UPLOAD
  ///////////////////

  uploadFile(
    fileName: string,
    file: File,
    bucket: string
  ): Promise<{ path: string }> {
    return new Promise<{ path: string }>((resolve, reject) => {
      _client.storage
        .from(bucket)
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true,
        })
        .then(async ({ data, error }) => {
          if (error || data?.path === null) reject(error);
          else {
            const newPath = await this.getFilesFromBucket([data.path], bucket);
            resolve({ path: newPath[0] });
          }
        })
        .catch((_) => {});
    });
  },

  async getFilesFromBucket(
    fileNames: string[],
    bucket: string
  ): Promise<string[]> {
    const fileUrls: string[] = [];

    for (const fileName of fileNames) {
      const response = await _client.storage
        .from(bucket)
        .createSignedUrl(fileName, 3600);
      if (response.data?.signedUrl) fileUrls.push(response.data?.signedUrl);
    }
    return fileUrls;
  },

  async downloadFile(path: string, bucket: string): Promise<Blob | null> {
    const { data, error } = await _client.storage.from(bucket).download(path);
    if (error) {
      return null;
    }

    return data;
  },
  async doFilesExist(
    fileNames: string[],
    path: string,
    bucket: string
  ): Promise<boolean[]> {
    const { data, error } = await _client.storage.from(bucket).list(path);
    console.log('List of files: ', data);
    if (error) return fileNames.map(() => false);
    return fileNames.map(
      (fileName) =>
        data?.map((storageFile) => storageFile.name).includes(fileName) ?? false
    );
  },
  //////////////////
  // WORKFORCE PLUS
  ///////////////////
  async getWorkforcePlusConfig(): Promise<WorkforcePlusConfigDto | null> {
    const { data } = await _client
      .from('api_integration')
      .select()
      .eq('company_id', getCompanyId());

    return dataOrThrowInvalidInput(data?.[0] ?? [], 'getWorkforcePlusConfig');
  },
  async changeWorkforcePlusConfig(
    newConfig: WorkforcePlusConfigDto
  ): Promise<WorkforcePlusConfigDto> {
    if (newConfig.company_id === undefined) {
      newConfig.company_id = getCompanyId();
    }

    const config = { ...newConfig };
    if (newConfig.id === undefined) {
      delete config.id;
    }

    const { data } = await _client
      .from('api_integration')
      .upsert(config)
      .eq('company_id', getCompanyId())
      .select()
      .single();

    return dataOrThrowInvalidInput(data, 'changeWorkforcePlusConfig');
  },
  async getWorkforceCompanyFilter(): Promise<WorkforcePlusCompanyFilterDto[]> {
    const { data } = await _client
      .from('fsi_user_filter')
      .select()
      .eq('company_id', getCompanyId());

    return dataOrThrowInvalidInput(data ?? [], 'getWorkforceCompanyFilter');
  },
  async updateWorkforceCompanyFilter(
    newFilter: WorkforcePlusCompanyFilterDto[]
  ): Promise<WorkforcePlusCompanyFilterDto[]> {
    await _client
      .from('fsi_user_filter')
      .delete()
      .eq('company_id', getCompanyId());

    const { data } = await _client
      .from('fsi_user_filter')
      .upsert(newFilter.map(filter => {
        const filterJson = { ...filter };
        delete filterJson.id;
        return filterJson;
      }))
      .eq('company_id', getCompanyId())
      .select();

    return dataOrThrowInvalidInput(data, 'updateWorkforceCompanyFilter');
  },
  async getWorkforceFilterKeys(): Promise<WorkforcePlusFilterKeyDto[]> {
    const { data } = await _client.from('fsi_user_filter_keys').select();

    return dataOrThrowInvalidInput(data, 'getWorkforceFilterKeys');
  },
  async updateWorkforceFilterKey(
    newConfig: WorkforcePlusFilterKeyDto
  ): Promise<WorkforcePlusFilterKeyDto> {
    const { data } = await _client
      .from('fsi_user_filter_keys')
      .upsert(newConfig)
      .select();

    return dataOrThrowInvalidInput(data, 'updateWorkForceFilterKeys');
  },

  //////////////////
  // COMPANY BRANDING
  ///////////////////
  async getCompanyBranding(): Promise<CompanyPropertyDto[]> {
    const { data } = await _client
      .from('company_properties')
      .select()
      .eq('company_id', getCompanyId());

    return dataOrThrowInvalidInput(data, 'getCompanyBranding');
  },

  async updateCompanyBranding(
    newCompanyData: CompanyPropertyDto[]
  ): Promise<CompanyPropertyDto[]> {
    const { data } = await _client
      .from('company_properties')
      .upsert(newCompanyData)
      .eq('company_id', getCompanyId())
      .select();

    return dataOrThrowInvalidInput(data, 'updateCompanyBranding');
  },

  //////////////////
  // PRIVACY POLICY AND TERMS OF USE
  ///////////////////
  async getTosOfCurrentCompany(): Promise<TosDto> {
    const response = await _client
      .from('tos')
      .select()
      .eq('company_id', getCompanyId())
      .single();

    return dataOrThrowInvalidInput(response.data, 'getTosOfCurrentCompany');
  },

  async updateTosOfCurrentCompany(newTos: TosDto): Promise<TosDto> {
    const { data, error } = await _client
      .from('tos')
      .upsert(newTos)
      .eq('company_id', getCompanyId())
      .select()
      .single();
    console.log(data, error);
    return dataOrThrowInvalidInput(data, 'updateTosOfCurrentCompany');
  },

  async refreshApproval(): Promise<void> {
    await _client
      .from('profile')
      .update({ accepted_tos_at: null })
      .eq('company_id', getCompanyId());
  },
};

function dataOrThrowInvalidInput<T>(data: any, methodName: string): T {
  if (data !== null) {
    return data as T;
  }

  throw new Error(`[${methodName}] Invalid input`);
}

function getCompanyId() {
  // TODO: later: maybe pass companyId to every API call instead of accessing zustand state here
  return companyStore.getState().selectedCompany?.id ?? '';
}

function throwError(error: AuthError | PostgrestError | null) {
  if (error) {
    if (error instanceof AuthError) {
      throw new AuthApiError(error.message, error.status);
    } else {
      throw new Error(`${error.message} [Details: ${error.details}]`);
    }
  }
  throw new Error('Unknown error');
}
