export class Tos {
  id: string;

  createdAt?: string;

  validAt?: string;

  updatedAt?: string;

  lastRefreshedAt?: string;

  agreement: string;

  privacyPolicy: string;

  companyId: string;

  constructor({
    id,
    agreement,
    privacyPolicy,
    companyId,
    createdAt,
    validAt,
    lastRefreshedAt,
    updatedAt,
  }: Tos) {
    this.id = id;
    this.createdAt = createdAt;
    this.validAt = validAt;
    this.lastRefreshedAt = lastRefreshedAt;
    this.agreement = agreement;
    this.privacyPolicy = privacyPolicy;
    this.companyId = companyId;
    this.updatedAt = updatedAt;
  }

  static fromJson(json: { [x: string]: unknown }): Tos {
    return new Tos({
      id: json.id as string,
      agreement: json.agreement as string,
      privacyPolicy: json.privacy_policy as string,
      companyId: json.company_id as string,
      createdAt: json.created_at as string,
      validAt: json.valid_at as string,
      lastRefreshedAt: json.last_refreshed_at as string,
      updatedAt: json.updated_at as string,
    });
  }

  static toJson(newTos: Tos): { [x: string]: unknown } {
    return {
      id: newTos.id,
      created_at: newTos.createdAt,
      valid_at: newTos.validAt,
      last_refreshed_at: newTos.lastRefreshedAt,
      agreement: newTos.agreement,
      privacy_policy: newTos.privacyPolicy,
      company_id: newTos.companyId,
      updated_at: newTos.updatedAt,
    };
  }
}
