import { create } from 'zustand';
import { CompanyProperty } from '../models/company-property.model';
import { companyBrandingRepository } from '../repository/company-branding-repository';

type State = {
  properties: CompanyProperty[];
  loading: boolean;
};

type Actions = {
  getCompanyBranding: () => void;
  updateCompanyBranding: (
    companyProperties: CompanyProperty[]
  ) => Promise<void>;
};

type CompanyBrandingStore = State & Actions;

const initialState = {
  properties: [],
  loading: false,
};

export const useCompanyBrandingStore = create<CompanyBrandingStore>((set) => ({
  ...initialState,

  getCompanyBranding: async () => {
    set({ loading: true });

    const response: CompanyProperty[] =
      await companyBrandingRepository.getCompanyBranding();

    set({
      properties: response,
      loading: false,
    });
  },

  updateCompanyBranding: async (
    companyProperties: CompanyProperty[]
  ): Promise<void> => {
    const updatedProperties: CompanyProperty[] =
      await companyBrandingRepository.updateCompanyBranding(companyProperties);

    set((state) => {
      return {
        ...state,
        properties: updatedProperties,
      };
    });
  },
}));
