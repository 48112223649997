import { create } from 'zustand';
import { Tos } from '../models/tos.model';
import { tosRepository } from '../repository/tos-repository';

type State = {
  currentTos?: Tos;
  loading: boolean;
};

type Actions = {
  getTosOfCurrentCompany: () => Promise<void>;
  updateTosOfCurrentCompany: (tos: Tos) => Promise<void>;
};

type TosStore = State & Actions;

const defaultTos: State = {
  loading: false,
};

export const useTosStore = create<TosStore>((set, get) => ({
  ...defaultTos,

  getTosOfCurrentCompany: async () => {
    set({ loading: true });

    const tos: Tos = await tosRepository.getTosOfCurrentCompany();

    set({
      currentTos: tos,
      loading: false,
    });
  },

  updateTosOfCurrentCompany: async (tos: Tos): Promise<void> => {
    await tosRepository.updateTosOfCurrentCompany(tos);

    set((state) => {
      return {
        ...state,
        currentTos: tos,
      };
    });
  },
}));
