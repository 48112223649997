import React from 'react';
import { Box, styled } from '@mui/material';
import { ReactComponent as CrossContentSvg } from '../../../assets/cross_content_icon.svg';

const StyledImageBox = styled(Box)(({ theme }) => ({
  borderRadius: 40,
  height: 200,
  width: 200,
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundColor: theme.palette.background.default,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  '& svg': {
    width: '80%',
    height: '80%',
    fill: theme.palette.text.primary,
    margin: '10%',
  },
}));

interface CompanyImageInterface {
  imageUrl?: string;
}

const CompanyImage: React.FC<CompanyImageInterface> = ({ imageUrl }) => {
  return (
    <StyledImageBox>
      {imageUrl ? <img src={imageUrl} alt="Company" /> : <CrossContentSvg />}
    </StyledImageBox>
  );
};

export default CompanyImage;
