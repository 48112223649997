import { create } from 'zustand';
import { WorkforcePlusCompanyFilterKey } from '../models/workforce-plus-company-filter-keys.model';
import { workforcePlusCompanyFilterKeyRepository } from '../repository/workforce-plus-company-filter-keys-repository';

type State = {
  keys: WorkforcePlusCompanyFilterKey[];
  loading: boolean;
};

type Actions = {
  getWorkforcePlusFilterKeys: () => void;
  updateWorkforcePlusFilterKey: (
    newKey: WorkforcePlusCompanyFilterKey
  ) => Promise<void>;
};

type WorkforcePlusFilterKeysStore = State & Actions;

const initialState = {
  keys: [],
  loading: false,
};

export const useWorkforcePlusCompanyFilterKeysStore = create<WorkforcePlusFilterKeysStore>(
  (set) => ({
    ...initialState,

    getWorkforcePlusFilterKeys: async () => {
      set({ loading: true });

      const response: WorkforcePlusCompanyFilterKey[] =
        await workforcePlusCompanyFilterKeyRepository.getWorkforcePlusFilterKeys();

      set({
        keys: response,
        loading: false,
      });
    },

    updateWorkforcePlusFilterKey: async (
      newFilterKey: WorkforcePlusCompanyFilterKey
    ): Promise<void> => {
      const updatedKey: WorkforcePlusCompanyFilterKey =
        await workforcePlusCompanyFilterKeyRepository.updateWorkforcePlusFilterKey(newFilterKey);

      set((state) => {
        return {
          ...state,
          keys: state.keys.some((key) => key.id === updatedKey.id)
            ? state.keys.map((key) =>
                key.id === updatedKey.id ? updatedKey : key
              )
            : [...state.keys, updatedKey],
        };
      });
    },
  })
);
