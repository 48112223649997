import { useEffect, useState } from 'react';
import { useForm as useReactForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';

type UseFormProps = {
  defaultValues: FieldValues;
};

export const useForm = <T>({ defaultValues }: UseFormProps) => {
  const [hasChanged, setHasChanged] = useState(false);
  const { control, handleSubmit, watch, reset, getValues, setValue } =
    useReactForm<FieldValues>({
      defaultValues,
    });

  const formValues: FieldValues = watch();

  useEffect(() => {
    const hasFormChanged = Object.keys(defaultValues).some(
      (key) => formValues[key] !== defaultValues[key]
    );
    setHasChanged(hasFormChanged);
  }, [defaultValues, formValues]);

  return {
    control,
    formValues: formValues as T,
    hasChanged,
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
  };
};
