import { UUID } from './typedefs';

export class WorkforcePlusCompanyFilter {
  id?: UUID;

  companyId: UUID;

  key: UUID;

  value: string;

  index: number;

  constructor({
    id,
    companyId,
    key,
    value,
    index,
  }: {
    id: UUID;
    companyId: UUID;
    key: UUID;
    value: string;
    index: number;
  }) {
    this.id = id;
    this.companyId = companyId;
    this.key = key;
    this.value = value;
    this.index = index;
  }

  static fromJson(json: { [x: string]: any }) {
    return new WorkforcePlusCompanyFilter({
      id: json.id as UUID,
      companyId: json.company_id as UUID,
      key: json.key as UUID,
      value: json.value as string,
      index: json.index as number,
    });
  }

  static toJson(config: WorkforcePlusCompanyFilter): { [x: string]: unknown } {
    return {
      id: config.id,
      company_id: config.companyId,
      key: config.key,
      value: config.value,
      index: config.index,
    };
  }
}
