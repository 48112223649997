export class WorkforcePlusConfig {
  id?: string;

  companyId?: string;

  url: string;

  clientId: string;

  clientSecret: string;

  intervalHours?: number;

  lastRunStarted?: string;

  lastRunFinished?: string;

  parameters?: { [key: string]: unknown };

  constructor({
    url,
    clientId,
    clientSecret,
    parameters,
    id,
    companyId,
    intervalHours,
    lastRunFinished,
    lastRunStarted,
  }: WorkforcePlusConfig) {
    this.url = url;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.parameters = parameters;
    this.id = id;
    this.companyId = companyId;
    this.intervalHours = intervalHours;
    this.lastRunStarted = lastRunStarted;
  }

  static fromJson(json: { [x: string]: unknown }) {
    const parameters = json.parameters as { [key: string]: any };

    return new WorkforcePlusConfig({
      url: json.url as string,
      clientId: (parameters?.environment?.wfp?.clientId ?? '') as string,
      clientSecret: (parameters?.environment?.wfp?.clientSecret ??
        '') as string,
      parameters: {
        ...parameters,
      },
      id: json.id as string,
      companyId: json.company_id as string,
      intervalHours: json.interval_hours as number,
      lastRunStarted: json.last_run_started as string,
      lastRunFinished: json.last_run_finished as string,
    });
  }

  static toJson(config: WorkforcePlusConfig): { [x: string]: unknown } {
    return {
      id: config.id,
      url: config.url,
      parameters: {
        ...config.parameters,
        environment: {
          wfp: {
            clientId: config.clientId,
            clientSecret: config.clientSecret,
          },
        },
      },
      company_id: config.companyId,
      interval_hours: config.intervalHours ?? 0,
      last_run_started: config.lastRunStarted,
      last_run_finished: config.lastRunFinished,
    };
  }
}
