import { client } from '../api/api.client';

type FileRepository = {
  uploadFileToStorage(
    file: File,
    fileName: string,
    bucket: string
  ): Promise<{ path: string }>;
  chooseFileFromLocalMachine(
    limitOfFiles: number | null,
    fileType?: string
  ): Promise<FileList | null>;
  getFilesFromBucket(fileNames: string[], bucket: string): Promise<string[]>;
  downloadFile(path: string, bucket: string): Promise<Blob | null>;
  doFilesExist(
    fileNames: string[],
    path: string,
    bucket: string
  ): Promise<boolean[]>;
};

export const fileRepository: FileRepository = {
  uploadFileToStorage(
    file: File,
    fileName: string,
    bucket: string
  ): Promise<{ path: string }> {
    return client.uploadFile(fileName, file, bucket);
  },
  chooseFileFromLocalMachine(
    limitOfFiles: number | null,
    fileType?: string
  ): Promise<FileList | null> {
    return new Promise<FileList | null>((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = limitOfFiles !== 1;
      if (fileType) {
        input.accept = fileType;
      }
      input.onchange = () => {
        if (input.files) resolve(input.files);
      };
      input.click();

      input.oncancel = () => {
        resolve(null);
      };
    });
  },
  getFilesFromBucket(fileNames: string[], bucket: string): Promise<string[]> {
    return client.getFilesFromBucket(fileNames, bucket);
  },
  downloadFile(path: string, bucket: string): Promise<Blob | null> {
    return client.downloadFile(path, bucket);
  },
  doFilesExist: async (fileNames, path, bucket) => {
    return client.doFilesExist(fileNames, path, bucket);
  },
};
