import { client } from '../api/api.client';

type PrivacyPolicyAndTermsOfUseRepository = {
  refreshApproval: () => Promise<void>;
};

const repository = (): PrivacyPolicyAndTermsOfUseRepository => {
  return {
    refreshApproval: async () => {
      await client.refreshApproval();
    },
  };
};

export const privacyPolicyAndTermsOfUseRepository = repository();
