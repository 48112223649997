import { create } from 'zustand';
import { featureFlagRepository } from '../repository/feature-flag-repository';
import { WorkforcePlusConfig } from '../models/workforce-plus-config.model';
import { workForcePlusConfigRepository } from '../repository/workforce-plus-config-repository';
import { generateUUID } from '../utils/uuid-generator';

type State = {
  config: WorkforcePlusConfig;
  loading: boolean;
};

type Actions = {
  getWorkforcePlusConfig: () => void;
  changeWorkforcePlusConfig: (newConfig: WorkforcePlusConfig) => Promise<void>;
};

type WorkForcePlusStore = State & Actions;

const defaultConfig: WorkforcePlusConfig = {
  url: '',
  clientId: '',
  clientSecret: '',
};

const initialState = {
  config: defaultConfig,
  loading: false,
};

export const useWorkforcePlusConfigStore = create<WorkForcePlusStore>((set) => ({
  ...initialState,

  getWorkforcePlusConfig: async () => {
    set({ loading: true });

    const response: WorkforcePlusConfig | null =
      await workForcePlusConfigRepository.getWorkforcePlusConfig();

    set({
      config: response ?? {
        url: '',
        clientId: '',
        clientSecret: '',
        parameters: {},
      },
      loading: false,
    });
  },

  changeWorkforcePlusConfig: async (
    newConfig: WorkforcePlusConfig
  ): Promise<void> => {
    const updatedConfig: WorkforcePlusConfig =
      await workForcePlusConfigRepository.changeWorkforcePlusConfig(newConfig);

    set((state) => {
      return {
        ...state,
        config: updatedConfig,
      };
    });
  },
}));
