import { supabaseAnonKey, supabaseUrl } from '../../environment';
import { UUID } from '../models/typedefs';
import { mockClient } from './mock.client';
// eslint-disable-next-line import/namespace
import { ContentItemUpdateDto } from './data/dto/content-item-update.dto';
import { ContentItemDto } from './data/dto/content-item.dto';
import { FeatureFlagDto } from './data/dto/feature-flag.dto';
import { UpdateSortingDto } from './data/dto/update-sorting.dto';
import { JSONArray, JSONObject } from './types';
import { WorkforcePlusConfigDto } from './data/dto/workforce-plus-config.dto';
import { CompanyPropertyDto } from './data/dto/company-property.dto';
import { WorkforcePlusCompanyFilterDto } from './data/dto/workforce-plus-company-filter.dto';
import { supabaseClient } from './supabase.client';
import { WorkforcePlusFilterKeyDto } from './data/dto/workforce-plus-filter-key.dto';
import { TosDto } from './data/dto/tos.dto';

export interface ApiClient {
  // todo: handle correct return types; usually it is not Promise<void>
  //////////////////
  // AUTHENTICATION
  //////////////////

  logIn(email: string, password: string, companyKey: string): Promise<void>;

  logOut(): Promise<void>;

  isAuthenticated(): Promise<boolean>;

  //////////////////
  // ADMIN
  //////////////////

  getAdmins(): Promise<JSONArray>;

  getAdmin(adminId: UUID): Promise<JSONObject>;

  addAdmin(jsonMap: JSONObject, currentPassword?: string): Promise<JSONObject>;

  updateAdmin(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject>;

  removeAdmin(adminId: UUID, currentPassword?: string): Promise<void>;

  //////////////////
  // USER
  //////////////////

  getUsers(): Promise<JSONArray>;

  getUsersForRange(
    start: number,
    end: number
  ): Promise<{ users: JSONArray; totalCount: number }>;

  getSelfUser(): Promise<JSONObject>;

  getUser(userId: UUID): Promise<JSONObject>;

  addUser(jsonMap: JSONObject): Promise<JSONObject>;

  updateUser(
    jsonMap: JSONObject,
    currentPassword?: string
  ): Promise<JSONObject>;

  //////////////////
  // COMPANY
  ///////////////////

  getCompanies(): Promise<JSONArray>;

  //////////////////
  // PROFILE PICTURE
  ///////////////////

  getProfilePicture(pictureId: UUID): Promise<Blob | null>;

  //////////////////
  // CROSS CONTENT ITEMS
  ///////////////////

  getCrossContentItems(): Promise<JSONArray>;

  createCrossContentItem(crossContentItem: ContentItemDto): Promise<JSONObject>;

  updateCrossContentItem(
    updateResource: ContentItemUpdateDto
  ): Promise<JSONObject>;

  deleteCrossContentItem(id: UUID): Promise<void>;

  updateSortingOfContentItems(ordering: UpdateSortingDto[]): Promise<JSONArray>;

  //////////////////
  // FEATURE FLAGS
  ///////////////////

  getFeatureFlags(): Promise<FeatureFlagDto[]>;

  toggleFeatureFlag(featureFlag: FeatureFlagDto): Promise<FeatureFlagDto>;

  //////////////////
  // FILES
  ///////////////////

  uploadFile(
    fileName: string,
    file: File,
    bucket: string
  ): Promise<{ path: string }>;

  getFilesFromBucket(fileNames: string[], bucket: string): Promise<string[]>;

  downloadFile(path: string, bucket: string): Promise<Blob | null>;

  doFilesExist(
    fileNames: string[],
    path: string,
    bucket: string
  ): Promise<boolean[]>;
  //////////////////
  // WORKFORCE PLUS
  ///////////////////

  getWorkforcePlusConfig(): Promise<WorkforcePlusConfigDto | null>;

  changeWorkforcePlusConfig(
    newConfig: WorkforcePlusConfigDto
  ): Promise<WorkforcePlusConfigDto>;

  getWorkforceCompanyFilter(): Promise<WorkforcePlusCompanyFilterDto[]>;

  updateWorkforceCompanyFilter(
    newFilter: WorkforcePlusCompanyFilterDto[]
  ): Promise<WorkforcePlusCompanyFilterDto[]>;

  getWorkforceFilterKeys(): Promise<WorkforcePlusFilterKeyDto[]>;

  updateWorkforceFilterKey(
    newKey: WorkforcePlusFilterKeyDto
  ): Promise<WorkforcePlusFilterKeyDto>;

  //////////////////
  // COMPANY BRANDING
  ///////////////////

  getCompanyBranding(): Promise<CompanyPropertyDto[]>;

  updateCompanyBranding(
    newCompanyData: CompanyPropertyDto[]
  ): Promise<CompanyPropertyDto[]>;

  //////////////////
  // PRIVACY POLICY AND TERMS OF USE
  ///////////////////
  getTosOfCurrentCompany(): Promise<TosDto>;

  updateTosOfCurrentCompany(newTos: TosDto): Promise<TosDto>;

  refreshApproval(): Promise<void>;
}

/** DECIDE HERE WHICH API CLIENT TO USE */
/**                                     */

let tempClient: ApiClient;
if (
  supabaseAnonKey !== undefined &&
  supabaseAnonKey.length > 0 &&
  supabaseUrl !== undefined &&
  supabaseUrl.length > 0
) {
  tempClient = supabaseClient;
} else {
  tempClient = mockClient;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const client: ApiClient = tempClient;

/**                                     */
/****************************************/
