import { create } from 'zustand';
import { workforcePlusCompanyFilterRepository } from '../repository/workforce-plus-company-filter-repository';
import { WorkforcePlusCompanyFilter } from '../models/workforce-plus-company-filter.model';

type State = {
  filter: WorkforcePlusCompanyFilter[];
  loading: boolean;
};

type Actions = {
  getWorkforcePlusCompanyFilter: () => void;
  updateWorkforcePlusFilter: (newFilter: WorkforcePlusCompanyFilter[]) => Promise<void>;
};

type WorkforcePlusFilterStore = State & Actions;

const initialState = {
  filter: [],
  loading: false,
};

export const useWorkforcePlusCompanyFilterStore = create<WorkforcePlusFilterStore>(
  (set) => ({
    ...initialState,

    getWorkforcePlusCompanyFilter: async () => {
      set({ loading: true });

      const response: WorkforcePlusCompanyFilter[] =
        await workforcePlusCompanyFilterRepository.getWorkforcePlusCompanyFilter();

      set({
        filter: response,
        loading: false,
      });
    },

    updateWorkforcePlusFilter: async (
      newFilter: WorkforcePlusCompanyFilter[]
    ): Promise<void> => {
      const updatedFilter: WorkforcePlusCompanyFilter[] =
        await workforcePlusCompanyFilterRepository.updateWorkforcePlusFilter(newFilter);

      set((state) => {
        return {
          ...state,
          filter: updatedFilter,
        };
      });
    },
  })
);
