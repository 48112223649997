import React, { useEffect, useMemo } from 'react';
import { useForm } from '../../../core/hooks/useForm';
import {
  Box,
  ButtonBase,
  Divider,
  FormGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { StyledFormGroup } from '../Settings.component';
import { Button } from '../../../core/components/button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import { useCompanyStore } from '../../../core/store/company-store/useCompanyStore';
import { privacyPolicyAndTermsOfUseRepository } from '../../../core/repository/privacy-policy-and-terms-of-use-repository';
import { useTosStore } from '../../../core/store/tos-store';
import { Tos } from '../../../core/models/tos.model';
import { useSnackbar } from 'notistack';
import { fileRepository } from '../../../core/repository/file-repository';
import { formatSupabaseDateString } from '../../../core/utils/date-time-formatter';
import { useTranslation } from 'react-i18next';

type PrivacyPolicyAndTermsOfUseValues = {
  files: FileDescription[];
};

type FileDescription = {
  name: string;
  path?: string;
  description: string;
  exists: boolean;
};

export const ToSSetting: React.FC = () => {
  //region STATE
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useCompanyStore();
  const { currentTos, getTosOfCurrentCompany, updateTosOfCurrentCompany } =
    useTosStore();
  const { formValues, reset: resetForm } =
    useForm<PrivacyPolicyAndTermsOfUseValues>({
      defaultValues: useMemo(() => {
        return getPrivacyPolicyAndTermsOfUseSettingDefaultValue();
      }, []),
    });
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    checkUploadedFiles();
  }, [selectedCompany?.id]);
  //endregion

  //region HANDLER
  const checkUploadedFiles = () => {
    if (selectedCompany?.id !== undefined) {
      getTosOfCurrentCompany()
        .then(() => {})
        .catch(() => {});
      const fileNames = formValues.files.map((f) => f.name);
      fileRepository
        .doFilesExist(fileNames, selectedCompany.id, 'company-agreements')
        .then((existsArray) => {
          const updatedFiles = formValues.files.map((f, index) => ({
            ...f,
            exists: existsArray[index],
          }));
          resetForm({
            files: updatedFiles,
          });
        })
        .catch(() => {});
    }
  };

  const pickHTML = async (
    e: React.MouseEvent<HTMLButtonElement>,
    fileDescription: FileDescription
  ) => {
    e.stopPropagation();
    const files = await fileRepository.chooseFileFromLocalMachine(
      1,
      'text/html'
    );
    const ownPath = `${selectedCompany?.id}/${fileDescription.name}`;
    if (files && files.length > 0) {
      fileRepository
        .uploadFileToStorage(
          files[0],
          `${selectedCompany?.id}/${fileDescription.name}`,
          'company-agreements'
        )
        .then(async (path) => {
          if (path && currentTos) {
            const newPathColumn: keyof Tos =
              fileDescription.name === 'privacy_policy.html'
                ? 'privacyPolicy'
                : 'agreement';
            await updateTosOfCurrentCompany({
              ...currentTos,
              updatedAt: DateTime.now().toString(),
              [newPathColumn]: ownPath,
            });
            const updatedFiles = formValues.files.map((f) =>
              f.name === fileDescription.name ? { ...f, path } : f
            );
            resetForm({
              files: updatedFiles,
            });
            enqueueSnackbar(t('settings.successfully_uploaded_file'), {
              variant: 'success',
            });
            checkUploadedFiles();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(t('settings.failed_to_upload_file'), {
            variant: 'error',
          });
        });
    }
  };

  const downloadConfidential = async (fileName: string) => {
    const file = await fileRepository.downloadFile(
      `${selectedCompany?.id}/${fileName}`,
      'company-agreements'
    );
    if (file) {
      const url = URL.createObjectURL(file);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor element and revoke the object URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const refreshApproval = () => {
    privacyPolicyAndTermsOfUseRepository
      .refreshApproval()
      .then(async () => {
        if (currentTos) {
          await updateTosOfCurrentCompany({
            ...currentTos,
            lastRefreshedAt: DateTime.now().toString(),
          });
        }
        enqueueSnackbar(t('settings.successfully_refreshed_approval'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('settings.failed_to_refresh_approval'), {
          variant: 'error',
        });
      });
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formValues);
  };

  //endregion

  //region RENDER
  const renderFileItem = (item: FileDescription) => (
    <Grid2 container xs={12} alignItems={'center'} rowSpacing={0}>
      <Grid2 xs={12} sm={6}>
        <Typography>{item.description}</Typography>
        <Typography color={'gray'} variant={'body2'}>
          {item.exists ? t('settings.uploaded') : t('settings.no_file_uploaded')}
        </Typography>
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <Stack justifyContent={'end'} direction={'row'} gap={0.5}>
          <Button onClick={(e) => pickHTML(e, item)}>{t('settings.choose_file')}</Button>
          <Button
            disabled={!item.exists}
            onClick={(e) => downloadConfidential(item.name)}
          >
            {t('download')}
          </Button>
        </Stack>
      </Grid2>
    </Grid2>
  );

  //endregion

  return (
    <Stack spacing={1}>
      <Typography fontSize={18} fontWeight={'bold'}>
        {t('settings.tosTitle')}
      </Typography>
      <StyledFormGroup>
        <form style={{ width: '100%' }} onSubmit={submit} noValidate>
          <FormGroup>
            <Stack spacing={2}>
              <Box>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ mt: 0, mb: 0.5 }} variant={'subtitle2'}>
                    {t('settings.filesTitle')}
                  </Typography>
                </Stack>
                <Divider />
              </Box>
              {formValues.files.map((item) => renderFileItem(item))}
              {currentTos?.updatedAt && (
                <Box textAlign={'right'} ml={'auto'}>
                  <Typography variant={'caption'}>{t('settings.updated_at')}</Typography>
                  <Typography>
                    {currentTos?.updatedAt &&
                      formatSupabaseDateString(
                        currentTos?.updatedAt,
                        DateTime.DATETIME_MED
                      )}
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography sx={{ mt: 3, mb: 0.5 }} variant={'subtitle2'}>
                  {t('settings.approvals')}
                </Typography>
                <Divider />
              </Box>
              <Typography variant={'caption'}>
                {t('settings.tos_refresh_info')}
              </Typography>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {currentTos?.lastRefreshedAt ? (
                  <Typography>
                    Last execution:{' '}
                    {formatSupabaseDateString(
                      currentTos.lastRefreshedAt,
                      DateTime.DATETIME_MED
                    )}
                  </Typography>
                ) : (
                  t('settings.never_refreshed')
                )}

                <Button sx={{ mt: 2 }} onClick={refreshApproval}>
                  {t('settings.refresh')}
                </Button>
              </Stack>
            </Stack>
          </FormGroup>
        </form>
      </StyledFormGroup>
    </Stack>
  );
};

const getPrivacyPolicyAndTermsOfUseSettingDefaultValue = (
  ciBrandingValues?: PrivacyPolicyAndTermsOfUseValues
): PrivacyPolicyAndTermsOfUseValues => {
  return {
    files: [
      {
        name: 'privacy_policy.html',
        description: 'Privacy Policy',
        exists: false,
      },
      {
        name: 'terms_of_use.html',
        description: 'Terms of use',
        exists: false,
      },
    ],
  };
};
