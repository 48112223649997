import React, { useEffect } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { useCompanyStore } from '../../../core/store/company-store/useCompanyStore';
import { Control, FieldValues } from 'react-hook-form';
import SelectFormFieldComponent from '../../../core/components/form/SelectFormField.component';
import { useWorkforcePlusCompanyFilterStore } from '../../../core/store/workforce-plus-company-filter.store';
import { UUID } from '../../../core/models/typedefs';
import { TextFormField } from '../../../core/components/form/TextFormField.component';
import { ReactComponent as SyncSvg } from '../../../assets/sync.svg';
import { useWorkforcePlusCompanyFilterKeysStore } from '../../../core/store/workforce-plus-company-keys.store';
import { useTranslation } from 'react-i18next';

const RotatingSyncSvg = styled(SyncSvg)`
    animation: rotate 4s linear infinite;
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
`;

interface WorkforcePlusFilterSettingProps {
  control: Control<FieldValues, any>;
  selectedKeyId: string;
  onFilterKeysChange: (selectedKeyId?: UUID, selectedFilter?: string) => void;
}

export const WorkforcePlusFilterSetting: React.FC<WorkforcePlusFilterSettingProps> = ({
                                                                                        control,
                                                                                        selectedKeyId,
                                                                                        onFilterKeysChange,
                                                                                      }) => {
  //region STATE
  const { t } = useTranslation();
  const { selectedCompany } = useCompanyStore();
  const { keys, getWorkforcePlusFilterKeys, loading: loadingKeys } = useWorkforcePlusCompanyFilterKeysStore();
  const { filter, getWorkforcePlusCompanyFilter, loading: loadingFilter } = useWorkforcePlusCompanyFilterStore();
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      getWorkforcePlusFilterKeys();
      getWorkforcePlusCompanyFilter();
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    if (loadingFilter || loadingKeys) return;

    if (keys.length > 0 && filter.length > 0) {
      const newSelectedKey = keys.find((key) => key.id === filter[0].key);
      if (newSelectedKey) {
        onFilterKeysChange(
          newSelectedKey.id,
          filter.map((item) => item.value).join(', '),
        );

        return;
      }
    }

    onFilterKeysChange(undefined, '');
  }, [loadingFilter, loadingKeys]);
  //endregion

  //region HANDLER
  //endregion

  //region RENDER
  return (
    <>
      <Typography pl={0.5} fontSize={12} color={'gray'}>
        {t('settings.workforce_plus_filter_description')}
      </Typography>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {!loadingKeys && <SelectFormFieldComponent
          fullWidth
          control={control}
          controlName={'selectedKeyId'}
          label={'Key'}
          options={[
            {
              title: 'Select Key',
              value: '',
              description: '',
            },
            ...keys.map((key) => ({
              title: key.name ?? '',
              value: key.id,
              description: key.key,
            })),
          ]}
        />}
        <TextFormField
          fullWidth
          label={'Values'}
          sx={{ width: '100%' }}
          disabled={selectedKeyId === "" || selectedKeyId === undefined || loadingKeys}
          control={control}
          controlName={'selectedFilter'}
        />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap={0.5}>
        <RotatingSyncSvg height={20} width={20} />
        <Typography pt={0.5} fontSize={12} color={'gray'}>
          {t('settings.workforce_plus_filter_info')}
        </Typography>
      </Stack>
    </>
  );
};
