import { Box, Stack, styled } from '@mui/material';

import React from 'react';
import { colors, dimensions, hexToRgbA } from '../../theme';

const PageLayoutContainer = styled(Stack, {
  name: 'PageLayout',
  slot: 'root',
})({
  paddingLeft: dimensions.main.padding.X,
  paddingRight: dimensions.main.padding.X,
  height: '100%',
});

const StyledHeader = styled(Stack, {
  name: 'PageLayout',
  slot: 'header',
})({
  width: '100%',
  height: dimensions.header.height,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  h2: {
    color: hexToRgbA(colors.font.dark, 0.87),
  },
});

const StyledDescription = styled(Box)({
  width: '100%',
  color: hexToRgbA(colors.font.dark, 0.6),
  marginBottom: 16,

  p: {
    margin: 0,
  },
});

type MainPageLayoutProps = {
  action: React.ReactNode;
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const MainPageLayout: React.FC<MainPageLayoutProps> = ({
  action,
  title,
  description,
  children,
}) => {
  return (
    <PageLayoutContainer spacing={2}>
      <Box flexDirection={'column'}>
        <StyledHeader>
          <h2>{title}</h2>
          {action}
        </StyledHeader>
        {description && (
          <StyledDescription>
            <p>{description}</p>
          </StyledDescription>
        )}
      </Box>

      {children}
    </PageLayoutContainer>
  );
};
