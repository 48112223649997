import { client } from '../api/api.client';
import { CompanyPropertyDto as JsonCompanyProperty } from '../api/data/dto/company-property.dto';
import { CompanyProperty } from '../models/company-property.model';

type CompanyBrandingRepository = {
  getCompanyBranding: () => Promise<CompanyProperty[]>;
  updateCompanyBranding: (
    newCompanyBranding: CompanyProperty[]
  ) => Promise<CompanyProperty[]>;
};

const repository = (): CompanyBrandingRepository => {
  return {
    getCompanyBranding: async (): Promise<CompanyProperty[]> => {
      const response: JsonCompanyProperty[] = await client.getCompanyBranding();

      const properties: CompanyProperty[] = response.map((property) => {
        return CompanyProperty.fromJson(property);
      });

      return properties;
    },

    updateCompanyBranding: async (
      newCompanyBranding: CompanyProperty[]
    ): Promise<CompanyProperty[]> => {
      const clientProperties: JsonCompanyProperty[] =
        await client.updateCompanyBranding(
          newCompanyBranding.map(
            (property) =>
              CompanyProperty.toJson(property) as JsonCompanyProperty
          )
        );

      return clientProperties.map((property) =>
        CompanyProperty.fromJson(property)
      );
    },
  };
};

export const companyBrandingRepository = repository();
