import { client } from '../api/api.client';
import { WorkforcePlusFilterKeyDto } from '../api/data/dto/workforce-plus-filter-key.dto';
import { WorkforcePlusCompanyFilterKey } from '../models/workforce-plus-company-filter-keys.model';

type WorkforcePlusCompanyFilterKeys = {
  getWorkforcePlusFilterKeys: () => Promise<WorkforcePlusCompanyFilterKey[]>;
  updateWorkforcePlusFilterKey: (
    newConfig: WorkforcePlusCompanyFilterKey
  ) => Promise<WorkforcePlusCompanyFilterKey>;
};

const repository = (): WorkforcePlusCompanyFilterKeys => {
  return {
    getWorkforcePlusFilterKeys: async (): Promise<WorkforcePlusCompanyFilterKey[]> => {
      const response: WorkforcePlusFilterKeyDto[] =
        await client.getWorkforceFilterKeys();

      return response.map((filterKey) =>
        WorkforcePlusCompanyFilterKey.fromJson(filterKey)
      );
    },

    updateWorkforcePlusFilterKey: async (
      newFilterKey: WorkforcePlusCompanyFilterKey
    ): Promise<WorkforcePlusCompanyFilterKey> => {
      const clientConfig: WorkforcePlusFilterKeyDto =
        await client.updateWorkforceFilterKey(
          WorkforcePlusCompanyFilterKey.toJson(newFilterKey) as WorkforcePlusFilterKeyDto
        );

      return WorkforcePlusCompanyFilterKey.fromJson(clientConfig);
    },
  };
};

export const workforcePlusCompanyFilterKeyRepository = repository();
