import { FormGroup, IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ImageSvg } from '../../../assets/image.svg';
import { Button } from '../../../core/components/button';
import { ColorFormField } from '../../../core/components/form/ColorFormField.component';
import CompanyImage from '../../../core/components/image/CompanyImage';
import { useForm } from '../../../core/hooks/useForm';
import { CompanyProperty } from '../../../core/models/company-property.model';
import { fileRepository } from '../../../core/repository/file-repository';
import { useCompanyBrandingStore } from '../../../core/store/company-branding.store';
import { useCompanyStore } from '../../../core/store/company-store/useCompanyStore';
import { StyledFormGroup } from '../Settings.component';

type CIBrandingValues = {
  logoUrl?: string;
  primary?: string;
  secondary?: string;
  alert?: string;
  warning?: string;
};

export const CIBrandingSetting: React.FC = () => {
  //region STATE
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useCompanyStore();
  const { properties, getCompanyBranding, updateCompanyBranding } =
    useCompanyBrandingStore();
  const {
    control,
    getValues,
    reset: resetForm,
    watch,
    setValue,
  } = useForm<CIBrandingValues>({
    defaultValues: useMemo(() => {
      return getDefaultCIBrandingValues();
    }, []),
  });

  const logoUrl = watch('logoUrl') as string;
  //endregion

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id) {
      getCompanyBranding();
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    if (properties) {
      setCompanyBranding();
    }
  }, [properties]);

  useEffect(() => {
    if (selectedCompany?.id) {
      loadCompanyImage().catch(() => {});
    }
  }, [selectedCompany?.id]);
  //endregion

  //region HANDLER

  const loadCompanyImage = async () => {
    if (!selectedCompany?.id) return;

    try {
      const urls = await fileRepository.getFilesFromBucket(
        [`${selectedCompany.id}.png`],
        'company-logo'
      );
      if (urls.length > 0) {
        const currentLogoUrl = urls[0];
        setValue('logoUrl', currentLogoUrl);
      } else {
        setValue('logoUrl', undefined);
      }
    } catch (e) {
      console.error('Error loading company logo', e);
      setValue('logoUrl', undefined);
    }
  };

  const setCompanyBranding = () => {
    setValue('primary', getCompanyPropertyValue('primary'));
    setValue('secondary', getCompanyPropertyValue('secondary'));
    setValue('alert', getCompanyPropertyValue('alert'));
    setValue('warning', getCompanyPropertyValue('warning'));
  };

  const getCompanyPropertyValue = (key: string): string => {
    return properties.find((property) => property.key === key)?.value ?? '';
  };

  const pickImage = async () => {
    const files = await fileRepository.chooseFileFromLocalMachine(1);

    if ((files?.length ?? 0) > 0 && selectedCompany?.id !== undefined) {
      try {
        await fileRepository.uploadFileToStorage(
          files![0],
          `${selectedCompany.id}.png`,
          'company-logo'
        );
        enqueueSnackbar(t('settings.logo_uploaded_successfully'), {
          variant: 'success',
        });
        loadCompanyImage().catch(() => {});
      } catch (e: any) {
        if (e.statusCode == '413') {
          enqueueSnackbar(t('file_size_too_large'), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t('settings.logo_upload_failed'), {
            variant: 'error',
          });
        }
      }
    }
  };

  const handleSaveBrandingValues = () => {
    const brandingValues = getValues() as CIBrandingValues;

    const keys = Object.entries(brandingValues).filter(([key, value]) => value);

    if (!selectedCompany?.id) {
      enqueueSnackbar(t('settings.no_company_selected'), { variant: 'error' });
      return;
    }

    const companyBranding: CompanyProperty[] = keys
      .filter(([key]) => key !== 'logoUrl')
      .map(([key, value]) => {
        return {
          id: properties.find((property) => property.key === key)?.id,
          key,
          value,
          companyId: selectedCompany.id,
        };
      });

    updateCompanyBranding(companyBranding)
      .then(() => {
        enqueueSnackbar(t('settings.successfully_saved_ci_branding'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('settings.failed_to_save_ci_branding'), {
          variant: 'error',
        });
      });
  };

  //endregion

  //region RENDER
  const renderTextFormField = (controlName: string, label: string) => {
    return (
      <ColorFormField
        control={control}
        controlName={controlName}
        label={label}
      />
    );
  };
  //endregion

  console.log('logo found render - ', logoUrl);

  return (
    <Stack spacing={1}>
      <Typography fontSize={18} fontWeight={'bold'}>
        CI Branding
      </Typography>
      <StyledFormGroup>
        <form style={{ width: '100%' }} noValidate>
          <FormGroup>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <IconButton onClick={pickImage}>
                {logoUrl ? (
                  <CompanyImage imageUrl={logoUrl} />
                ) : (
                  <ImageSvg
                    style={{
                      height: '200px',
                      width: '200px',
                    }}
                  />
                )}
              </IconButton>
              <Stack spacing={2} flex={1}>
                {renderTextFormField('primary', 'Primary Color')}
                {renderTextFormField('secondary', 'Secondary Color')}
                {renderTextFormField('alert', 'Alert Color')}
                {renderTextFormField('warning', 'Warning Color')}
              </Stack>
            </Stack>
            <Button
              onClick={handleSaveBrandingValues}
              variant={'contained'}
              sx={{ ml: 'auto', mt: 1 }}
            >
              {t('settings.save')}
            </Button>
          </FormGroup>
        </form>
      </StyledFormGroup>
    </Stack>
  );
};

const getDefaultCIBrandingValues = (
  ciBrandingValues?: CIBrandingValues
): CIBrandingValues => {
  return {
    logoUrl: ciBrandingValues?.logoUrl,
    primary: ciBrandingValues?.primary ?? '',
    secondary: ciBrandingValues?.secondary ?? '',
    alert: ciBrandingValues?.alert ?? '',
    warning: ciBrandingValues?.warning ?? '',
  };
};
