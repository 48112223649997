import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import ContentImage from '../image/ContentImage';
import { Button } from '../button';
import { ReactComponent as UploadIcon } from '../../../assets/upload.svg';
import { create } from 'zustand';
import { StyledError } from '../form/TextFormField.component';
import ImageCropDialog, {
  ImageCropDialogFunctions,
} from '../image-crop/ImageCropDialog.component';
import {
  convertBlobToFile,
  convertFileToString,
} from '../../utils/file-helper';
import { fileRepository } from '../../repository/file-repository';

interface IAvatarUploaderProps {
  imageUrl?: string;
  onFileUpload: (file: File) => Promise<string>;
}

interface ContentImageStore {
  loading: boolean;
  set: (state: Partial<ContentImageStore>) => void;
  image?: string;
  error: string;
}

const useContentImageStore = create<ContentImageStore>((set) => ({
  image: undefined,
  loading: false,
  error: '',
  set: (state) => set(state),
}));

const AvatarUploader: React.FC<IAvatarUploaderProps> = ({
  imageUrl,
  onFileUpload,
}) => {
  const contentImageStore = useContentImageStore();
  const cropRef = useRef<ImageCropDialogFunctions>(null);

  useEffect(() => {
    contentImageStore.set({ image: imageUrl });
  }, [imageUrl]);

  return (
    <>
      <ImageCropDialog ref={cropRef} />
      <Stack>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <ContentImage imageUrl={contentImageStore.image} />
          <Button
            loading={contentImageStore.loading}
            onClick={handleImageUploadClick}
            variant={'outlined'}
            startIcon={<UploadIcon />}
          >
            Choose file
          </Button>
        </Stack>
        <Box height={10}>
          {contentImageStore.error && (
            <StyledError sx={{ ml: 0 }}>{contentImageStore.error}</StyledError>
          )}
        </Box>
      </Stack>
    </>
  );

  async function handleImageUploadClick() {
    contentImageStore.set({ loading: true, error: '' });

    try {
      const file = await getFileFromMachineChecked();
      const croppedFile = await cropImageAndWaitForCropped(file);
      const uploadedFile = await onFileUpload(croppedFile);
      contentImageStore.set({ image: uploadedFile, loading: false });
    } catch (e) {
      contentImageStore.set({
        loading: false,
        error: 'Error uploading image. Please try again.',
      });
    }
    contentImageStore.set({ loading: false });
  }

  async function cropImageAndWaitForCropped(file: File): Promise<File> {
    if (!cropRef.current) {
      throw new Error('Crop ref not set');
    }
    const imageString = await convertFileToString(file);
    const croppedImage = await cropRef.current.open(imageString);
    return convertBlobToFile(croppedImage, file.name);
  }

  async function getFileFromMachineChecked() {
    const fileList = await fileRepository.chooseFileFromLocalMachine(1);
    if (fileList == null) {
      contentImageStore.set({ loading: false });
      throw new Error('No file selected');
    }
    return fileList[0];
  }
};

export default AvatarUploader;
