import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../core/components/button';
import { MainPageLayout } from '../../core/components/layout';
import { CircularProgress } from '../../core/components/loader/CircularProgress.component';
import { useCompanyStore } from '../../core/store/company-store/useCompanyStore';
import { useFeatureFlagStore } from '../../core/store/feature-flag.store';
import { WorkforcePlusSetting } from './components/WorkforcePlusSetting.component';
import { CIBrandingSetting } from './components/CIBrandingSetting.component';
import { ToSSetting } from './components/ToSSetting';

export const Settings = () => {
  //region STATE & HOOKS
  const { t } = useTranslation();
  const {
    featureFlags,
    loading,
    getFeatureFlags,
    toggleFeatureFlag: updateFeatureFlag,
  } = useFeatureFlagStore();
  const { selectedCompany } = useCompanyStore();

  //region LIFECYCLE
  useEffect(() => {
    if (selectedCompany?.id !== undefined) {
      getFeatureFlags();
    }
  }, [selectedCompany?.id]);

  //region RENDER COMPONENT
  return (
    <MainPageLayout
      action={undefined}
      title={t('settings.title')}
      description={t('settings.description')}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={4} pb={2}>
          <ToSSetting />
          <CIBrandingSetting />
          {renderFormGroup()}
          <WorkforcePlusSetting />
        </Stack>
      )}
    </MainPageLayout>
  );

  //region RENDER BLOCKS
  function renderFormGroup(): JSX.Element {
    return (
      <Box>
        <Typography mt={1} fontSize={18} fontWeight={'bold'}>
          Feature Flags
        </Typography>

        <StyledFormGroup>
          <FormGroup>
            {featureFlags?.map((featureFlag, index) => (
              <FormControlLabel
                key={featureFlag.id ?? index}
                control={
                  <Switch
                    sx={{ m: 1 }}
                    checked={featureFlag.value}
                    onChange={(_, checked) =>
                      updateFeatureFlag({ ...featureFlag, value: checked })
                    }
                  />
                }
                label={t(`settings.features.${featureFlag.key}`)}
              />
            ))}
          </FormGroup>
        </StyledFormGroup>
      </Box>
    );
  }
};

export const StyledFormGroup = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}));
