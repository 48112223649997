import { UUID } from './typedefs';
import { DateTime } from 'luxon';
import { generateUUID } from '../utils/uuid-generator';

export class CompanyProperty {
  id?: UUID;

  companyId: UUID;

  key: string;

  value: string;

  createdAt?: string;

  constructor({
    id,
    companyId,
    key,
    value,
    createdAt,
  }: {
    id?: UUID;
    companyId: UUID;
    key: string;
    value: string;
    createdAt?: string;
  }) {
    this.id = id;
    this.companyId = companyId;
    this.key = key;
    this.value = value;
    this.createdAt = createdAt;
  }

  static fromJson(json: { [x: string]: any }): CompanyProperty {
    return new CompanyProperty({
      id: json.id as string,
      companyId: json.company_id as string,
      key: json.key as string,
      value: json.value as string,
      createdAt: json.created_at as string,
    });
  }

  static toJson(companyProperty: CompanyProperty): { [x: string]: unknown } {
    return {
      id: companyProperty.id ?? generateUUID(),
      company_id: companyProperty.companyId,
      key: companyProperty.key,
      value: companyProperty.value,
      created_at: companyProperty.createdAt ?? DateTime.now(),
    };
  }
}
