import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import AvatarUploader from '../../../../core/components/avatar/AvatarUploader.component';
import { ContentItemFormValues } from '../../../../core/models/content-item-form-values';
import { TextFormField } from '../../../../core/components/form/TextFormField.component';
import { Stack, Tooltip } from '@mui/material';
import { ReactComponent as InfoCircle } from '../../../../assets/info_circle.svg';
import DateFormField from '../../../../core/components/form/DateFormField';
import { requiredRule } from '../../../../core/components/form/rules/required';
import Grid2 from '@mui/material/Unstable_Grid2';
import SelectFormFieldComponent from '../../../../core/components/form/SelectFormField.component';
import { visibilityFormFieldOptions } from './visibility-form-field-options';
import SwitchFormField from '../../../../core/components/form/SwitchFormField.component';
import { generateUUID } from '../../../../core/utils/uuid-generator';
import { fileRepository } from '../../../../core/repository/file-repository';

interface IContentItemFormProps {
  formProps: UseFormReturn<ContentItemFormValues>;
}

const ContentItemForm: React.FC<IContentItemFormProps> = ({ formProps }) => {
  const { control, watch } = formProps;

  return (
    <Grid2 container spacing={2}>
      {renderAvatarUploader()}
      {renderTitleField()}
      {renderURLField()}
      {renderValidRange()}
      {renderVisibilityOptions()}
      {renderEmbeddedBrowserSwitch()}
      {renderDescription()}
    </Grid2>
  );

  async function handleFileUpload(file: File) {
    const fileName = generateUUID();
    const { path } = await fileRepository.uploadFileToStorage(
      file,
      fileName,
      'content-image-icon'
    );
    formProps.setValue('imagePath', path, { shouldDirty: true });
    return URL.createObjectURL(file);
  }

  function renderTitleField() {
    const labelLength = watch('label')?.length || 0;
    return (
      <Grid2 xs={12}>
        <Stack direction={'row'} alignItems={'start'} gap={0.5}>
          <TextFormField
            rules={{
              max: { value: 30, message: 'Label is too long' },
              required: requiredRule,
            }}
            label={'Label'}
            helperText={`${30 - labelLength} characters remaining.`}
            control={control}
            controlName={'label'}
          />
        </Stack>
      </Grid2>
    );
  }

  function renderURLField() {
    return (
      <Grid2 xs={12}>
        <TextFormField
          rules={{ required: requiredRule }}
          label={'URL'}
          control={control}
          controlName={'link'}
        />
      </Grid2>
    );
  }

  function renderValidRange() {
    return (
      <>
        <Grid2 xs={12} md={6}>
          <DateFormField
            sx={{ width: '100%' }}
            label={'Valid from'}
            control={control}
            controlName={'validFrom'}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <DateFormField
            sx={{ width: '100%' }}
            label={'Valid to'}
            control={control}
            controlName={'validUntil'}
          />
        </Grid2>
      </>
    );
  }

  function renderAvatarUploader() {
    return (
      <Grid2 xs={12}>
        <AvatarUploader
          onFileUpload={handleFileUpload}
          imageUrl={formProps.getValues().icon}
        />
      </Grid2>
    );
  }

  function renderVisibilityOptions() {
    return (
      <>
        <Grid2 xs={12} md={6}>
          <SelectFormFieldComponent
            fullWidth
            control={control}
            controlName={'visibility'}
            options={visibilityFormFieldOptions}
            label={'Visibility'}
            rules={{ required: requiredRule }}
          />
        </Grid2>
        <Grid2 xs={false} md={6} />
      </>
    );
  }

  function renderEmbeddedBrowserSwitch() {
    return (
      <>
        <Grid2 xs={false}>
          <SwitchFormField
            label={'Open in embedded Browser'}
            control={control}
            controlName={'openInEmbeddedBrowser'}
          />
        </Grid2>
        <Grid2 display={'flex'} alignItems={'center'} xs={1}>
          <Tooltip
            placement={'right'}
            title={
              'If this option has been activated, the link is opened in a browser integrated in frapp, otherwise in the system browser.'
            }
          >
            <InfoCircle />
          </Tooltip>
        </Grid2>
      </>
    );
  }

  function renderDescription() {
    return (
      <Grid2 xs={12}>
        <TextFormField
          label={'Description'}
          helperText={'internal, not visible in the App'}
          control={control}
          controlName={'description'}
          multiline
          rows={3}
        />
      </Grid2>
    );
  }
};

export default ContentItemForm;
