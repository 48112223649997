/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { BasicSelectProps, ValidationRules } from './types';

interface ISelectFormFieldProps extends BasicSelectProps {
  rules?: ValidationRules;
}

const SelectFormFieldComponent: React.FC<ISelectFormFieldProps> = ({
  control,
  controlName,
  rules,
  options,
  ...rest
}) => {
  const [maxWidthForMenuItems, setMaxWidthForMenuItems] = useState<number>(0);

  useLayoutEffect(() => {
    const selectFieldRef = document.getElementById(
      'select-field-width-helper-element'
    );
    if (selectFieldRef == null) return;

    const selectSizeListener = () => {
      if (selectFieldRef) {
        setMaxWidthForMenuItems(selectFieldRef.clientWidth);
      }
    };
    selectSizeListener();

    window.addEventListener('resize', selectSizeListener);
    return () => window.removeEventListener('resize', selectSizeListener);
  }, []);

  function renderSelectValue(value: string) {
    return (
      <Typography>
        {options.find((x: { value: string }) => x.value == value)?.title ?? ''}
      </Typography>
    );
  }

  function renderSelectElement(
    index: number,
    option: { title: string; description?: string; value: string }
  ) {
    return (
      <MenuItem
        sx={{ width: maxWidthForMenuItems }}
        key={index}
        value={option.value}
      >
        <Stack direction={'column'}>
          <Typography fontWeight={'bold'} variant={'body1'}>
            {option.title}
          </Typography>
          <Typography
            whiteSpace={'pre-wrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            variant={'body2'}
          >
            {option.description}
          </Typography>
        </Stack>
      </MenuItem>
    );
  }

  return (
    <Controller
      name={controlName}
      control={control}
      rules={rules}
      defaultValue={rest.defaultValue}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id="select-label">{rest.label}</InputLabel>
          <div id={'select-field-width-helper-element'}>
            <Select
              renderValue={renderSelectValue}
              autoWidth={false}
              {...rest}
              {...field}
              variant={'outlined'}
            >
              {options.map(
                (
                  option: {
                    title: string;
                    description?: string;
                    value: string;
                  },
                  index: number
                ) => renderSelectElement(index, option)
              )}
            </Select>
          </div>
        </FormControl>
      )}
    />
  );
};

export default SelectFormFieldComponent;
