import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ConfirmProvider } from 'material-ui-confirm';
import { createRoot } from 'react-dom/client';
import App from './App';

import ErrorBoundary from './core/components/error/ErrorBoundary.component';
import { theme } from './core/theme';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <ThemeProvider theme={theme}>
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: {
            variant: 'contained',
          },
        }}
      >
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
